   .timeline {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow-x: auto;
    padding: 2rem 0;
    width: 200%;
    bottom: 30px;
    
  }
  
  .timeline-line {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 4px;
    background-color: #e9ecef;
    z-index: 0;
    width: 100%; /* Make the line longer */
  
  }
  /* .arrow-left {
    
    top: 40%;
    left: 0;
    transform: translateY(-50%);
    border: solid #e9ecef;
    border-width: 0 4px 4px 0;
    display: inline-block;
    padding: 10px;
    transform: rotate(135deg);
  } */
  
  .timeline-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin: 0 2rem;
  }
  
  .timeline-header {
    background-color: #f8f9fa;
    padding: 0.5rem 1rem;
    border-radius: 6px 6px 0 0;
    text-align: center;
    width: 100%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1;
  }
  
  .timeline-content {
    background-color: #fff;
    padding: 1rem 2rem;
    border-radius: 0 0 6px 6px;
    position: relative;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
    z-index: 1;
  }
  
  .timeline-item::after {
    content: "";
    position: absolute;
    width: 4px;
    height: 20px;
    background-color: #e9ecef;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .timeline-item h4 {
    margin: 0;
  }
  
  .timeline-item span {
    color: #6c757d;
  }
  
  .timeline-item p {
    margin: 0;
    color: #6c757d;
  } 
    
